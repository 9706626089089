import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

import "../styles/styles.css"
import "../styles/layout-styles.css"
import "../styles/custom-styles.css"

const RecentScholarlyImpactPage = () => (
  <Layout>
    <Seo title="Recent Scholarly Impact" />
    <div id="content">
      <div id="feature_box"></div>
      <div class="post_box top" id="post-629">
        <div class="format_text">
          <h1>Recent Scholarly Impact</h1>
          <p>
            <StaticImage
              src="../images/shutterstock_53884903-Education-300x200.jpeg"
              alt="Columns"
            />
            <br />
            By one measure of scholarly impact, on November 1, 2024, his
            scholarship is <b>ranked 15th</b> by the Social Sciences Research
            Network (SSRN)&apos;s Top 3,000 Law Authors category, equal to the
            scholarly output of the <b>94th-ranked</b> U.S. law school;{" "}
            <b>ranked 66th worldwide</b>
            among SSRN&apos;s Top 12,000 Business Authors (based on total new
            downloads within the past twelve months), equivalent to scholarly
            output of the <b>106st-ranked</b> U.S. business school at the same
            date.
          </p>
          <p>
            SEC Commissioner Luis Aguilar in a June 10, 2014 speech before the
            New York Stock exchange “Cyber Risks and the Boardroom” Conference,
            cited my article (see footnotes 19, 37 &amp; 40),{" "}
            <a href="https://www.sec.gov/News/Speech/Detail/Speech/1370542057946#.U5xzFPldWHg">
              here
            </a>
            . An April 4, 2017 Fast Company Magazine story about the Panama
            Papers cites my scholarship{" "}
            <a href="https://www.fastcompany.com/3069249/what-icijs-data-and-tech-team-learned-from-the-panama-papers-one-year-later">
              here
            </a>
            .
          </p>
          <p>
            In their letter to the SEC dated March 31, 2015, public fund
            fiduciaries, who collectively supervise the investment of over $1.12
            trillion in assets, cited my article, Corporate Director Selection
            and Recruitment: A Matrix, in their petition for rulemaking, asking
            “the Commission to require new disclosures related to nominees for
            corporate board seats… to provide investors with necessary
            information to evaluate the nominees’ gender, racial, and ethnic
            diversity, as well as their mix of skills, experiences, and
            attributes needed to fulfill the corporation’s mission,”{" "}
            <a href="http://www.ott.ct.gov/PolicyAndEducation/SECProxyRuleAmendmentPetition033115.pdf">
              here
            </a>
            .
          </p>
          <p>
            On May 9, 2022, Professor Neal F. Newman and I proposed an SEC Cyber
            Data Disclosure Advisory Commission, please see{" "}
            <a href="http://ssrn.com/abstract=4097138">
              http://ssrn.com/abstract=4097138
            </a>
            . (In response to a Request for Comment to an SEC Proposed Rule
            [File No. S7-09-22], Cybersecurity Risk Management, Strategy,
            Governance, and Incident Disclosure). Then, on June 17, 2022 we
            provided comments in response to the SEC’s Proposed Rule [File No.
            S7-10-22] for The Enhancement and Standardization of Climate-related
            Disclosures for Investors, redrafted as a law review article and
            posted here,{" "}
            <a href="http://ssrn.com/abstract=3939898">
              http://ssrn.com/abstract=3939898
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default RecentScholarlyImpactPage
